/**
 * getFriendlyFloat(num, maximumFractionDigits)
 *
 * Formats a number as a string with a specified number of decimal places.
 *
 * @param num
 * @param maximumFractionDigits
 * @returns string
 */
export const getFriendlyFloat = (num: number | string, maximumFractionDigits = 2) => {
  const number = Number(num)

  return number.toLocaleString("en-US", { maximumFractionDigits })
}

/**
 * roundToDecimalPlace
 *
 * Rounds a number with the specified number of decimal places.
 * Output is still a number.
 *
 * @param num number to be rounded
 * @param decimalPlaces How many decimal places provided number should be rounded to
 * @returns number, rounded from `num`
 */
export const roundToDecimalPlace = (num: number, decimalPlaces: number): number => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.round(num * factor) / factor
}
