// Daily, Weekly, 2-Weeks, 4-Weeks, 3 Months, 6 Months, Yearly

export const InventoryIntervalOptions: { [key: string]: number } = {
  Daily: 86400,
  Weekly: 604800,
  "2 Weeks": 604800 * 2,
  "4 Weeks": 604800 * 4,
  "3 Months": 86400 * 90,
  "6 Months": 86400 * 182.5,
  Yearly: 86400 * 365,
}

export const InventoryIntervalLookup: { [key: number]: string } = {
  86400: "Daily",
  604800: "Weekly",
  1209600: "2 Weeks",
  2419200: "4 Weeks",
  7776000: "3 Months",
  15768000: "6 Months",
  31536000: "Yearly",
}
