import { clsx } from "clsx"
import { FC } from "react"

type Props = {
  className: string
}

export const SkeletonCard: FC<Props> = ({ className }) => {
  return <div className={clsx("bg-gray-200 rounded-lg", className)} />
}
