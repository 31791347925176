import { useField } from "formik"
import { FC } from "react"
import { getSetIntersection, getSetSubtraction, getSetUnion } from "../../../helpers/getBooleanSet"
import { uniqueBy } from "../../../helpers/uniqueBy"
import { isOneDimensional } from "../../../helpers/util-functions"
import { MultiSelectOption } from "./MuiMultiSelect"

type Props = {
  name: string
  matchedOptions: MultiSelectOption[] | MultiSelectOption[][]
  selectedOptions: MultiSelectOption[] | MultiSelectOption[][]
  setSelectedOptions?: (newSelectedOptions: MultiSelectOption[] | MultiSelectOption[][]) => void
}

const buildOptionsSet = (options: MultiSelectOption[] | MultiSelectOption[][]) => {
  const optionsSet = new Set()

  options.flat().forEach((option) => optionsSet.add(option.id))

  return optionsSet
}

export const SelectAll: FC<Props> = ({ name, matchedOptions, selectedOptions, setSelectedOptions }) => {
  const [_, __, helpers] = useField(name)
  const isGrouped = !isOneDimensional(selectedOptions)

  return (
    <label className="p-5 border-b group grid items-center grid-cols-12 gap-x-3 text-sm cursor-pointer rounded relative bg-white transition-all ease-in-out duration-200 hover:bg-gray-100 w-full">
      <input
        type="checkbox"
        className="col-span-1 focus:ring-blue-500 size-4 text-blue-600 border-gray-400 rounded-sm border-2"
        onChange={(e) => {
          if (e.target.checked) {
            // Combine the sets of selected and matched options
            // There should be no duplicates
            const updatedOptionsSet = getSetUnion(buildOptionsSet(selectedOptions), buildOptionsSet(matchedOptions))
            const newSelectedItems: MultiSelectOption[] = uniqueBy<MultiSelectOption>(
              [...(selectedOptions?.flat() || []), ...(matchedOptions?.flat() || [])],
              (option: MultiSelectOption) => option.id
            )

            setSelectedOptions?.(newSelectedItems)
            helpers.setValue([...updatedOptionsSet])
          } else {
            // Filter matched search results from the list of selected options
            // Deselection should only remove matched options
            const filteredOptionsSet = getSetSubtraction(
              buildOptionsSet(selectedOptions),
              buildOptionsSet(matchedOptions)
            )
            const newSelectedItems: MultiSelectOption[] | MultiSelectOption[][] = isGrouped
              ? (selectedOptions as MultiSelectOption[][]).map((selectedOptionGroup) =>
                  selectedOptionGroup.filter((selectedOption) => filteredOptionsSet.has(selectedOption.id))
                )
              : (selectedOptions as MultiSelectOption[]).filter((selectedOption) =>
                  filteredOptionsSet.has(selectedOption.id)
                )

            setSelectedOptions?.(newSelectedItems)
            helpers.setValue([...filteredOptionsSet])
          }
        }}
        checked={
          getSetIntersection(buildOptionsSet(selectedOptions), buildOptionsSet(matchedOptions)).size ===
          matchedOptions.flat().length
        }
      />
      <span className="col-span-11">
        Select All <span className="text-gray-400">({matchedOptions.flat().length})</span>
      </span>
    </label>
  )
}
