export function formatPhoneNumber(phoneNumberString: string): string {
  const numbersOnly = phoneNumberString.replace(/\D/g, "")
  const regexMatch = numbersOnly.match(/([2-9])(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?(\d)?$/)

  if (regexMatch) {
    const matches = regexMatch.filter((match) => match)
    if (matches.length < 2) {
      return ``
    }
    if (matches.length === 2) {
      return `(${matches[1]}`
    }
    if (matches.length === 3) {
      return `(${matches[1]}${matches[2]}`
    }
    if (matches.length === 4) {
      return `(${matches[1]}${matches[2]}${matches[3]}`
    }
    if (matches.length === 5) {
      return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}`
    }
    if (matches.length === 6) {
      return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}${matches[5]}`
    }
    if (matches.length === 7) {
      return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}${matches[5]}${matches[6]}`
    }
    if (matches.length === 8) {
      return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}${matches[5]}${matches[6]}-${matches[7]}`
    }
    if (matches.length === 9) {
      return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}${matches[5]}${matches[6]}-${matches[7]}${matches[8]}`
    }
    if (matches.length === 10) {
      return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}${matches[5]}${matches[6]}-${matches[7]}${matches[8]}${matches[9]}`
    }

    return `(${matches[1]}${matches[2]}${matches[3]}) ${matches[4]}${matches[5]}${matches[6]}-${matches[7]}${matches[8]}${matches[9]}${matches[10]}`
  }
  return ""
}
