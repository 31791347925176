import { FC } from "react"
import { ButtonFilled, ButtonHollow } from "../../../deprecated"
import { ModalBody } from "../Elements/ModalBody"
import { ModalFooter } from "../Elements/ModalFooter"
import { SkeletonContainer } from "../../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../Skeletons/SkeletonElement"

type TransferAssetModalSkeletonProps = {
  handleClose: () => void
}

export const TransferAssetModalSkeleton: FC<TransferAssetModalSkeletonProps> = ({ handleClose }) => {
  return (
    <>
      <ModalBody className="min-h-[250px]">
        <SkeletonContainer>
          <div className="grid gap-5">
            <SkeletonElement className="h-10" />
            <SkeletonElement className="h-10" />
          </div>
        </SkeletonContainer>
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type="button" onClick={handleClose}>
          Cancel
        </ButtonHollow>
        <ButtonFilled type="submit" disabled={true}>
          Reassign
        </ButtonFilled>
      </ModalFooter>
    </>
  )
}
