import * as Yup from "yup"
import { AssetRetainmentOption } from "../../../../graphql/generated/client-types-and-hooks"

export const validationSchema = Yup.object().shape({
  selectedDivisionId: Yup.string().required("Please select a division."),
  assignment: Yup.object().shape({
    assignmentType: Yup.string().oneOf(["User", "Asset", "Task"]).required("Required"),
    selectedAssetId: Yup.string().when("assignmentType", {
      is: "Asset",
      then: (schema) => schema.required("You must have an asset to reassign this asset."),
    }),
    selectedProjectId: Yup.mixed().when("assignmentType", {
      is: "Task",
      then: (schema) =>
        schema
          .test(
            "is-string-or-array-of-string",
            "selectedProjectId must be a string or an array of strings",
            (value) =>
              typeof value === "string" || (Array.isArray(value) && value.every((item) => typeof item === "string"))
          )
          .required("You must have a project to transfer this asset."),
    }),
    selectedTaskId: Yup.mixed().when("assignmentType", {
      is: "Task",
      then: (schema) =>
        schema
          .test(
            "is-string-or-array-of-string",
            "selectedTaskId must be a string or an array of strings",
            (value) =>
              typeof value === "string" || (Array.isArray(value) && value.every((item) => typeof item === "string"))
          )
          .required("You must select a task to transfer this asset."),
    }),
    selectedUserIds: Yup.mixed().when("assignmentType", {
      is: "User",
      then: (schema) =>
        schema
          .test(
            "is-string-or-array-of-string",
            "selectedUserIds must be a string or an array of strings",
            (value) =>
              typeof value === "string" || (Array.isArray(value) && value.every((item) => typeof item === "string"))
          )
          .required("You must select a user to transfer this asset."),
    }),
  }),
  assetRetainment: Yup.mixed().oneOf([AssetRetainmentOption.Offload, AssetRetainmentOption.Retain]),
})
