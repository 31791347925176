export const pluralize = (term: string, count: number): string => {
  const lowerCaseTerm = term.toLowerCase()
  if (count === 1) return term

  switch (lowerCaseTerm) {
    case "each":
      return "Each"
    case "was":
      return "were"
    case "is":
      return "are"
    case "yard":
      return "Yards"
    default:
      // Do nothing if the last letter is "s".
      if (term.slice(-1) === "s") {
        return term
      }

      return term + "s"
  }
}
