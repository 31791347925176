import { Box, Skeleton } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect, useMemo } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { ListVisibilityFilter, User } from "../../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { usePrevious } from "../../../../hooks/usePrevious"
import { MuiGroupedSingleSelect, SingleSelectGroupedOption } from "../MuiGroupedSingleSelect"
import { MuiMultiSelect, MultiSelectOption } from "../MuiMultiSelect"
import { createTaskOptionGroups } from "../helpers"
import { useCurrentUser } from "../../../../providers/PermissionsProvider/currentUserProvider"
import { ProjectStatus } from "../../../../graphql/generated/client-types-and-hooks"

type Props = {
  formGroupId: string
  divisionId?: string | null
  users?: Pick<User, "id" | "taskId" | "currentTaskId">[]
  excludeTaskIds?: string[] | null
  excludedProjectId?: string
  includeCurrentAssignment?: boolean
  includeBreakTasks?: boolean
  withErrorHandling?: boolean
  clearable?: boolean
  includeCompleted?: boolean
  includeArchived?: boolean
  closedAfter?: Date | null
  disabled?: boolean
  setIsUnpaid?: (value: boolean) => void
}

const ProjectAndTasksMultiSelect = graphql(`
  query ProjectAndTasksMultiSelect(
    $status: ProjectStatus!
    $divisionId: String
    $excludedProjectId: String
    $visibilityFilter: ListVisibilityFilter!
    $includeBreakTasks: Boolean
    $closedAfter: DateTime
    $includeCompleted: Boolean
    $includeArchived: Boolean
  ) {
    projectsByStatus(
      status: $status
      divisionId: $divisionId
      excludedProjectId: $excludedProjectId
      visibilityFilter: $visibilityFilter
      closedAfter: $closedAfter
    ) {
      id
      divisionId
      organizationId
      name
      isDefault
      taskGroups {
        id
        name
        sortOrder
      }
      tasks(
        visibilityFilter: $visibilityFilter
        includeBreakTasks: $includeBreakTasks
        closedAfter: $closedAfter
        includeCompleted: $includeCompleted
        includeArchived: $includeArchived
      ) {
        id
        name
        groupId
        sortOrder
        isComplete
        archived
        isUnpaid
      }
    }
  }
`)

export const ProjectAndTasksMultiSelects: FC<Props> = ({
  formGroupId,
  divisionId,
  includeCurrentAssignment,
  includeBreakTasks,
  users = [],
  excludeTaskIds = [],
  excludedProjectId,
  withErrorHandling = false,
  clearable = true,
  includeArchived,
  includeCompleted,
  closedAfter,
  disabled = false,
  setIsUnpaid,
}) => {
  const { projectFieldName, taskFieldName } = useMemo(
    () => ({
      projectFieldName: `${formGroupId}.selectedProjectId`,
      taskFieldName: `${formGroupId}.selectedTaskId`,
    }),
    [formGroupId]
  )

  const [projectField, _projectMeta, _projectHelpers] = useField(projectFieldName)
  const [taskField, _taskMeta, taskHelpers] = useField(taskFieldName)

  const projectId = projectField?.value?.at?.(0)
  const previousProjectId = usePrevious(projectId)

  const currentUser = useCurrentUser()

  const [{ data, error, fetching }] = useQuery({
    query: ProjectAndTasksMultiSelect,
    variables: {
      status: closedAfter ? ProjectStatus.All : ProjectStatus.Active,
      divisionId,
      excludedProjectId,
      visibilityFilter: currentUser.defaultListVisibilityFilter as ListVisibilityFilter,
      includeBreakTasks,
      includeArchived,
      includeCompleted,
      closedAfter,
    },
  })

  useHandleError(error, "Could not load required data")

  useEffect(() => {
    if (!includeBreakTasks || !setIsUnpaid) return

    const [selectedProjectId] = projectField.value
    const selectedTaskId = taskField.value
    if (selectedProjectId && selectedTaskId) {
      const selectedTask = data?.projectsByStatus
        .find((p) => p.id === selectedProjectId)
        ?.tasks.find((t) => t.id === selectedTaskId)

      setIsUnpaid?.(selectedTask?.isUnpaid ?? false)
    }
  }, [data?.projectsByStatus, includeBreakTasks, projectField.value, taskField.value, setIsUnpaid])

  useEffect(() => {
    if (previousProjectId && projectId !== previousProjectId) {
      taskHelpers.setValue([])
    }
  }, [projectId, previousProjectId, taskHelpers])

  const availableProjectsOptions = useMemo<MultiSelectOption[]>(() => {
    return (data?.projectsByStatus || []).map((project) => ({
      id: project.id,
      label: project.name,
      value: project.id,
      searchableTextString: project.name,
      template: (_item: MultiSelectOption) => <div>{project.name}</div>,
    })) as MultiSelectOption[]
  }, [data?.projectsByStatus])

  const availableGroupedTaskOptions = useMemo<SingleSelectGroupedOption[]>(() => {
    const tasks = data?.projectsByStatus.find((p) => p.id === projectId)?.tasks || []
    const taskGroups = data?.projectsByStatus.find((p) => p.id === projectId)?.taskGroups || []
    const options = { users, includeCurrentAssignment, excludeTaskIds }
    return createTaskOptionGroups(tasks, taskGroups, options)
  }, [data?.projectsByStatus, projectId, users, includeCurrentAssignment, excludeTaskIds])

  if (fetching && !data) {
    return (
      <Box className="flex gap-6 flex-col md:gap-4">
        <Skeleton
          className="mb-4"
          test-label="loading-project-multi-select"
          variant="rectangular"
          width="100%"
          height="40px"
          animation="wave"
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          test-label="loading-task-multi-select"
          variant="rectangular"
          width="100%"
          height="40px"
          animation="wave"
          sx={{ borderRadius: 1 }}
        />
      </Box>
    )
  }

  return (
    <Box className="flex gap-6 flex-col md:gap-4">
      <MuiMultiSelect
        containerClassName="flex-1"
        label="Project"
        name={projectFieldName}
        selectedLabel="project"
        options={availableProjectsOptions}
        withErrorHandling={withErrorHandling}
        disabled={disabled}
        required
        isSingleSelect
      />
      <MuiGroupedSingleSelect
        containerClassName="flex-1"
        label="Task"
        name={taskFieldName}
        selectedLabel="task"
        groupedOptions={availableGroupedTaskOptions}
        withErrorHandling={withErrorHandling}
        disabled={disabled}
        required
        clearable={clearable}
      />
    </Box>
  )
}
