import { Task, TaskGroup, User } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"

type PickTask = PickPlus<Task, "id" | "name" | "groupId" | "sortOrder" | "isComplete" | "archived">
type PickTaskGroup = PickPlus<TaskGroup, "id" | "name" | "sortOrder">

// Define the structure of the group objects in the accumulator
interface GroupSelect {
  groupName: string | null
  groupId: string | null
  sortOrder?: number | null | undefined
  options: Array<{
    id: string
    label: string
    value: string
    sortOrder?: number | null | undefined
    searchableTextString: string
  }>
}

export const createTaskOptionGroups = (
  tasks: PickTask[],
  taskGroups: PickTaskGroup[],
  options: {
    users?: Pick<User, "id" | "taskId" | "currentTaskId">[]
    includeCurrentAssignment?: boolean
    excludedTaskIds?: string[] | null
    filterCompletedTasks?: boolean | true
  }
) => {
  // Filter out excluded, archived, and completed tasks.
  // Include tasks that are assigned to the current user if includeCurrentAssignment is true.
  // Sort the tasks by sortOrder
  const filteredAndSortedTasks = tasks
    .filter((task) => {
      if (options?.excludedTaskIds?.includes(task.id)) {
        return false
      }

      if (task.archived) {
        return false
      }

      if (options?.filterCompletedTasks && task.isComplete) {
        return false
      }

      if (!options?.users) {
        return true
      }

      if (options?.includeCurrentAssignment) {
        return true
      }

      return options?.users.some((user) => user?.currentTaskId !== task.id)
    })
    .sort((a, b) => (a.sortOrder && b.sortOrder ? a.sortOrder - b.sortOrder : 0))

  // Separate tasks into sub tasks and 'regular' tasks
  const [subTasks, normalTasks] = filteredAndSortedTasks.reduce<[PickTask[], PickTask[]]>(
    ([subTaskList, normalTaskList], item) => {
      if (item.groupId) {
        subTaskList.push(item)
      } else {
        normalTaskList.push(item)
      }
      return [subTaskList, normalTaskList]
    },
    [[], []]
  )

  // Start creating the grouped options, starting with task groups and the sub tasks that belong to them.
  const orderedTaskGroupOptions = taskGroups.map((group): GroupSelect => {
    const subTasksForGroup = subTasks.filter((t) => t.groupId === group.id)
    return mapTaskGroupToOption(group, subTasksForGroup)
  })

  // Continue creating the grouped options with the remaining, regular tasks
  const orderedTaskOptions = normalTasks.reduce<GroupSelect[]>((acc, task) => {
    const previousElement = acc[acc.length - 1]
    // If the previous element is a group and the most recent option in that group has a sortOrder that is one less
    // than the current task's sortOrder, then they belong in the same menu group.
    if (
      previousElement &&
      previousElement.options &&
      task.sortOrder &&
      (previousElement.options[previousElement.options.length - 1].sortOrder === task.sortOrder - 1 ||
        previousElement.options[previousElement.options.length - 1].sortOrder === -1) // Unassigned Task sortOrder is -1
    ) {
      previousElement.options.push(mapTaskToOption(task))
    } else {
      // Otherwise, create a new group
      const groupValues = { id: "", name: "", sortOrder: task.sortOrder }
      acc.push(mapTaskGroupToOption(groupValues, [task]))
    }
    return acc
  }, [])

  // Sort the groups and tasks by sortOrder
  const sortedTasksAndGroups = [...orderedTaskGroupOptions, ...orderedTaskOptions].sort((a, b) => {
    // Treat null or undefined sortOrder as a large number to ensure these items end up last
    const sortOrderA = a.sortOrder != null ? a.sortOrder : Number.MAX_SAFE_INTEGER
    const sortOrderB = b.sortOrder != null ? b.sortOrder : Number.MAX_SAFE_INTEGER
    return sortOrderA - sortOrderB
  })

  return sortedTasksAndGroups
}

function mapTaskGroupToOption(group: PickTaskGroup, tasks: PickTask[]) {
  return {
    groupName: group.name,
    groupId: group.id,
    sortOrder: group.sortOrder,
    options: tasks.map(mapTaskToOption),
  }
}

function mapTaskToOption(task: PickTask) {
  return {
    id: task.id,
    label: task.name,
    value: task.id,
    sortOrder: task.sortOrder,
    searchableTextString: task.name,
  }
}
