import { FormControlLabel, Radio } from "@mui/material"
import { useField } from "formik"
import { FC } from "react"

type RadioGroupProps = {
  name: string
  containerClassName?: string
  options: {
    label: string
    value: string
    disabled?: boolean
  }[]
}

export const RadioGroup: FC<RadioGroupProps> = ({ name, options, containerClassName }) => {
  const [field, meta, helpers] = useField(name)
  const { value } = field
  const { error, touched } = meta

  return (
    <div className={containerClassName}>
      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0">
        {options.map((option) => (
          <div key={option.value} className="flex items-center">
            <FormControlLabel
              checked={value === option.value}
              control={<Radio />}
              label={option.label}
              disabled={!!option.disabled}
              name={name}
              onChange={() => {
                helpers.setValue(option.value)
              }}
              value={option.value}
            />
          </div>
        ))}
      </div>
      {touched && error && <div className="text-red-600 text-sm">{error}</div>}
    </div>
  )
}
