export const getSetIntersection = <T>(setA: Set<T>, setB: Set<T>) => {
  return new Set([...setA].filter((element) => setB.has(element)))
}

export const getSetSubtraction = <T>(a: Set<T>, b: Set<T>) => {
  return new Set([...a].filter((x) => !b.has(x)))
}

export const getSetUnion = <T>(setA: Set<T>, setB: Set<T>) => {
  return new Set([...setA, ...setB])
}
