import { FC } from "react"
import { BiDotsHorizontalRounded } from "react-icons/bi"
import { IconBaseProps } from "react-icons/lib"

type Props = IconBaseProps & {
  className?: string
}

export const QuickMenuDotsHorizontal: FC<Props> = ({ className = "w-6 h-6 text-gray-800", ...props }) => {
  // eslint-disable-next-line localrules/cannot-use-bidotshorizontalrounded
  return <BiDotsHorizontalRounded className={className} {...props} />
}
