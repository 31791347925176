import { TextField as MuiTextField, TextFieldProps } from "@mui/material"
import { useField } from "formik"
import { formatMoney } from "../../helpers/formatters/formatMoney"
import { formatPhoneNumber } from "../../helpers/formatters/formatPhoneNumber"
import { errorSnack } from "../Notistack/ThemedSnackbars"
import { getFriendlyFloat } from "../../helpers/numbers/getFriendlyFloat"
import { FC } from "react"

export const TextField: FC<TextFieldProps & { name: string }> = ({ name, ...props }) => {
  const [{ value = "" }, { touched, error }, { setValue }] = useField(name)
  const displayError = touched && error && !props.disabled

  const max = 1000000000

  const inputProps = {
    max: props.type === "number" ? max : undefined,
    ...props.InputProps?.inputProps,
    ...props.inputProps,
  }

  return (
    <MuiTextField
      InputProps={{
        inputProps,
      }}
      {...props}
      size="small"
      value={value}
      onChange={(e) => {
        if (props.onChange) props.onChange(e)
        const val = e.target.value
        if (props.type === "tel") {
          setValue(formatPhoneNumber(val))
        } else if (props.type === "money") {
          const append = val.at(-1) === "." ? "." : ""
          const decimalPlaces = val.split(".").at(1)?.length || 0
          const valueAsNumber = Number(val.replace(/[^0-9.-]+/g, ""))
          setValue(`${formatMoney(valueAsNumber, Math.min(decimalPlaces, 2))}${append}`)
        } else if (props.type === "number" && val !== "") {
          // if a user keys in a number higher than the max we still need to alert them
          if (+val > (inputProps.max || max)) {
            errorSnack(`The maximum value is ${getFriendlyFloat(inputProps.max || max)}`)
            return
          }

          setValue(parseFloat(Math.min(+val, inputProps.max || max).toString()))
        } else {
          setValue(val)
        }
      }}
      helperText={displayError ? error : <>&nbsp;</>}
      error={!!displayError}
      FormHelperTextProps={{ className: "my-0.5" }}
    />
  )
}
