export function formatMoney(money: number | undefined | null, minimumFractionDigits = 2): string {
  if (!money) return ""

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits: 2,
  })
  return formatter.format(money)
}
