// Shamelessly adapted from https://stackoverflow.com/a/40808569

export const uniqueBy = <T>(array: T[], predicate: keyof T | ((item: T) => PropertyKey)): T[] => {
  const callback: (item: T) => PropertyKey =
    typeof predicate === "function" ? predicate : (item: T) => item[predicate] as PropertyKey

  return [
    ...array
      .reduce((map, item) => {
        const key: PropertyKey = item === null || item === undefined ? Symbol() : callback(item)

        map.has(key) || map.set(key, item)

        return map
      }, new Map<PropertyKey, T>())
      .values(),
  ]
}
