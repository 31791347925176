import { FC } from "react"
import { useQuery } from "urql"
import { User } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { PickPlus } from "../../../../types/helpers"
import { Monogram } from "../../../Monogram"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "currentProjectId" | "currentTaskId" | "lastName" | "jobTitle"> & {
    projectId?: User["projectId"]
    taskId?: User["taskId"]
  }
}

const UserWithTaskRow: FC<Props> = ({ user }) => {
  const TaskAndProjectQuery = graphql(`
    query UserWithTaskRowQuery($taskId: String!, $projectId: String!) {
      task(id: $taskId) {
        id
        name
      }
      project(id: $projectId) {
        id
        name
      }
    }
  `)
  const [{ data }] = useQuery({
    query: TaskAndProjectQuery,
    requestPolicy: "cache-first",
    variables: {
      taskId: user.currentTaskId || user.taskId!,
      projectId: user.currentProjectId || user.projectId!,
    },
    pause: !user,
  })

  return (
    <div className="grid grid-cols-12 gap-4 justify-between w-full">
      <div className="col-span-7 flex items-center gap-x-3">
        <Monogram user={user} className="size-7 min-w-[28px] text-[9px] group-hover:bg-gray-25 transition-colors" />
        <div className="grow flex flex-col truncate">
          <p className="text-gray-800 leading-tight truncate">{user.firstName + " " + user.lastName}</p>
          <p className="text-gray-400 text-xs leading-tight truncate">{user.jobTitle}</p>
        </div>
      </div>
      <div className="col-span-5 truncate">
        <p className="text-blue-600 leading-tight truncate">{data?.task?.name}</p>
        <p className="text-gray-400 text-xs leading-tight truncate">{data?.project?.name}</p>
      </div>
    </div>
  )
}

export default UserWithTaskRow
