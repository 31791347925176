import {
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { useField } from "formik"
import { FC, ReactNode, useState } from "react"
import { BiX } from "react-icons/bi"
import { pluralize } from "../../../helpers/strings/pluralize"
import { TailwindIcon } from "../../../types/tailwind"

type SingleSelectOption = {
  id: string
  label: string
  value: string
  searchableTextString: string
  template?: (item: SingleSelectOption) => JSX.Element
  disabled?: boolean
}

export type SingleSelectGroupedOption = {
  groupName: string | null
  options: SingleSelectOption[]
}

type Props = {
  containerClassName?: string
  label: string
  name: string
  Icon?: TailwindIcon
  placeholder?: string
  groupedOptions: SingleSelectGroupedOption[]
  clearable?: boolean
  disabled?: boolean
  disabledOptionIds?: string[]
  required?: boolean
  selectedLabel?: string
  withErrorHandling?: boolean
  onChange?: (selectedValues: string) => void
}

export const MuiGroupedSingleSelect: FC<Props> = ({
  containerClassName,
  label,
  name,
  Icon,
  placeholder,
  groupedOptions,
  required,
  clearable = false,
  disabled = false,
  disabledOptionIds = [],
  selectedLabel,
  withErrorHandling = false,
  onChange = () => {},
}) => {
  const [open, setOpen] = useState(false)
  const [field, meta, helpers] = useField(name)

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value
    helpers.setValue(newValue)
    onChange?.(newValue)
  }

  const optionLabel = selectedLabel || label || "option"
  const renderValue = (selected: string[] | string): ReactNode => {
    if (!selected || selected.length === 0) return placeholder || `Select ${optionLabel}`
    const newSelection = Array.isArray(selected) ? selected[0] : selected
    const selectedOption = groupedOptions.flatMap((group) => group.options).find((option) => option.id === newSelection)

    return selectedOption ? selectedOption.label : placeholder || `Select ${optionLabel}`
  }

  return (
    <FormControl
      fullWidth
      size="small"
      className={containerClassName}
      required={required}
      error={meta.touched && !!meta.error}
    >
      {label && (
        <InputLabel id={`${name}-label`} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Select
        aria-label={label}
        aria-labelledby={`${name}-label`}
        id={name}
        label={label}
        labelId={`${name}-label`}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        renderValue={renderValue}
        defaultValue=""
        value={field.value ?? ""}
        disabled={disabled}
        startAdornment={
          Icon && (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          )
        }
        endAdornment={
          clearable && field.value ? (
            <InputAdornment position="end">
              <BiX
                className="mr-5"
                onClick={() => {
                  helpers.setValue("")
                  onChange?.("")
                }}
                role="button"
                aria-label="Clear selection"
              />
            </InputAdornment>
          ) : null
        }
      >
        <MenuItem disabled value="">
          {groupedOptions.length ? (
            placeholder ?? `Select ${optionLabel}`
          ) : (
            <em>No {pluralize(optionLabel, 0)} available to assign</em>
          )}
        </MenuItem>
        {groupedOptions.length
          ? groupedOptions.map((group, index) => [
              index !== 0 && <Divider key={`divider-${index}`} />,
              <ListSubheader key={`group-${index}`} className="font-bold text-black">
                {group.groupName}
              </ListSubheader>,
              ...group.options.map((option, index2) => (
                <MenuItem
                  key={`option-${index}-${index2}`}
                  value={option.id}
                  disabled={option.disabled || disabledOptionIds.includes(option.id)}
                  onClick={() => {
                    helpers.setValue(option.value)
                  }}
                >
                  {option.label}
                </MenuItem>
              )),
            ])
          : null}
      </Select>
      {withErrorHandling && meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
