import { FC, useEffect, useState } from "react"
import { Alert } from "@mui/material"
import { Formik, Form, FormikHelpers, FormikValues } from "formik"
import { useQuery } from "urql"
import {
  AssetRetainmentOption,
  useSwitchDivisionForAssetMutation,
} from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { useCurrentUser } from "../../../../providers/PermissionsProvider/currentUserProvider"
import { ModalProps } from "../../hooks/useModalProps"
import { MuiModal } from "../Elements/MuiModal"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { TransferAssetModalSkeleton } from "./TransferAssetModal.skeleton"
import { TransferAssetToDivisionForm } from "./TransferAssetToDivisionForm"
import { validationSchema } from "./transferAssetToDivisionValidation"

const TransferAssetToDivisionQuery = graphql(`
  query TransferAssetToDivisionQuery($assetId: String!) {
    asset(id: $assetId) {
      id
      name
      assetChildCount
      hasQuantizedAssets
    }
  }
`)

interface FormValues {
  selectedDivisionId: string
  assignment: {
    assignmentType: string
    selectedAssetId: string
    selectedProjectId: string[] | string
    selectedTaskId: string[] | string
    selectedUserIds: string[] | string
  }
  assetRetainment: AssetRetainmentOption
}

interface AssetError extends Error {
  message: string
}

export const TransferAssetToDivisionModal: FC<{
  isAssetGroup?: boolean
  modalProps: ModalProps
  selectedAssetId: string
  onSuccess?: (assetIds: string[]) => void
}> = ({ isAssetGroup, modalProps, selectedAssetId, onSuccess }) => {
  const [{ data, fetching }] = useQuery({
    query: TransferAssetToDivisionQuery,
    variables: {
      assetId: selectedAssetId,
    },
  })

  const componentUIState = !data?.asset && fetching ? "fetching" : "loaded"
  const currentUser = useCurrentUser()
  const [, switchDivisionForAssetMutation] = useSwitchDivisionForAssetMutation()
  const [showAssetError, setShowAssetError] = useState<boolean>(isAssetGroup ?? false)

  useEffect(() => {
    const hasQuantizedAssets = data?.asset?.hasQuantizedAssets
    setShowAssetError((isAssetGroup || hasQuantizedAssets) ?? false)
  }, [isAssetGroup, data])

  const handleSubmissionError = (error: AssetError) => {
    let message = "An error was encountered while switching the asset division."
    console.error(error)
    if (error?.message.includes("Cannot retain nested assets in an asset group.")) {
      setShowAssetError(true)
    } else {
      message += ` Please try again.`
    }
    errorSnack(message)
  }

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      setSubmitting(true)

      let selectedAssignable
      let selectedProjectId

      const { assignment } = values
      const { assignmentType } = assignment
      switch (assignmentType) {
        case "User":
          if (Array.isArray(assignment.selectedUserIds)) {
            ;[selectedAssignable] = assignment.selectedUserIds
          } else {
            selectedAssignable = assignment.selectedUserIds
          }
          break
        case "Asset":
          selectedAssignable = assignment.selectedAssetId
          break
        case "Task":
          if (Array.isArray(assignment.selectedTaskId)) {
            ;[selectedAssignable] = assignment.selectedTaskId
            ;[selectedProjectId] = assignment.selectedProjectId
          } else {
            selectedAssignable = assignment.selectedTaskId
            selectedProjectId = Array.isArray(assignment.selectedProjectId)
              ? assignment.selectedProjectId[0]
              : assignment.selectedProjectId
          }
          break
        default:
          throw new Error("Invalid assignment type", assignmentType)
      }

      const result = await switchDivisionForAssetMutation({
        assetId: selectedAssetId,
        organizationId: currentUser.organizationId,
        divisionId: values.selectedDivisionId,
        assignableId: selectedAssignable,
        assignableType: assignmentType,
        projectIdIfTask: selectedProjectId,
        assetRetainment: values.assetRetainment,
      })

      if (result.error) {
        handleSubmissionError(result.error)
      } else {
        successSnack("Asset Division Successfully Switched")
        if (onSuccess) onSuccess([selectedAssetId])
        modalProps.handleCloseModal()
      }
    } catch (error) {
      handleSubmissionError(error as AssetError)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        selectedDivisionId: "",
        assignment: {
          assignmentType: "Task",
          selectedAssetId: "",
          selectedProjectId: "",
          selectedTaskId: "",
          selectedUserIds: [],
        },
        assetRetainment: AssetRetainmentOption.Offload,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form className="h-full flex flex-col">
          <MuiModal
            variant="small"
            isOpen={modalProps.isOpen}
            contentLabel="Switch Asset Division"
            handleCloseModal={() => {
              modalProps.handleCloseModal()
              resetForm()
            }}
            submitButtonText="Transfer"
            submitForm={submitForm}
            submitButtonColor="primary"
            disabled={isSubmitting || !selectedAssetId}
            isLoading={false}
          >
            {componentUIState === "fetching" && (
              <TransferAssetModalSkeleton handleClose={modalProps.handleCloseModal} />
            )}

            {componentUIState === "loaded" && (
              <>
                <TransferAssetToDivisionForm assetIdToTransfer={selectedAssetId} hasQuantizedAssets={showAssetError} />

                {showAssetError && (
                  <Alert severity="info" className="mt-0 mb-6">
                    This asset contains quantized assets. Quantized assets cannot be transferred to another division. If
                    you need further assistance, please contact support at{" "}
                    <a className="underline" href="mailto:support@crewview.com">
                      support@crewview.com
                    </a>
                  </Alert>
                )}
              </>
            )}
          </MuiModal>
        </Form>
      )}
    </Formik>
  )
}
