import { clsx } from "clsx"
import { ReactNode } from "react"

// DrawerFooter:  We recommend no padding/margin on the inset children
export const DrawerFooter = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return (
    // This is a bit of a hack to make the footer stick to the bottom of the screen but also provide enough
    // padding to prevent other elements from sliding underneath it.
    <>
      <div className="pb-[min(16px,3%)] h-12" />
      <div className={clsx("fixed bottom-0 border-t z-10 pt-4 pb-[min(16px,3%)] bg-white w-full", className)}>
        {children}
      </div>
    </>
  )
}
