import { Alert, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useField } from "formik"
import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { AssetRetainmentOption } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { SelectableUser } from "../../../../types/User"
import { ProjectAndTasksMultiSelects } from "../../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { UserSelect } from "../../../Formik/UserSelect"
import { RadioGroup as FormikRadioGroup } from "../../../Formik/RadioGroup"
import { DivisionSelect } from "../../../Formik/DivisionSelect"
import { useCurrentUser } from "../../../../providers/PermissionsProvider/currentUserProvider"

const GetSelectedAssetDocument = graphql(`
  query SelectedAssetDocument($assetId: String!) {
    asset(id: $assetId) {
      id
      assetChildCount
      reportTemplates {
        id
        name
      }
    }
  }
`)

const GetAssetDivisionTransferDocument = graphql(`
  query TransferAssetDivisionModalGetEntities($divisionId: String) {
    assets(includeGroupedAssets: false, divisionId: $divisionId) {
      id
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUserId
      assignedUser {
        id
        firstName
        imageUrl
        jobTitle
        lastName
      }

      active
      assetChildCount
      companyAssetNumber
      compositeKey
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }

    assetGroups(divisionId: $divisionId) {
      assetGroupId
      assignableId
      assignableType
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      compositeKey
      count
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
      status
    }

    reusableAssetReportTemplates(divisionId: $divisionId, filterByActiveTemplates: true) {
      id
      name
    }
  }
`)

const DivisionsForAssetTransferDocument = graphql(`
  query DivisionsForAssetTransfer {
    myDivisions(includeAll: true) {
      id
      name
      imageUrl
      isPrimary
    }
  }
`)

type FormContentsProps = {
  assetIdToTransfer: string
  hasQuantizedAssets: boolean
  projectId?: string
  selectedUser?: SelectableUser | undefined
}

export const TransferAssetToDivisionForm: FC<FormContentsProps> = ({
  assetIdToTransfer,
  hasQuantizedAssets,
  projectId = "",
  selectedUser,
}) => {
  const currentUser = useCurrentUser()
  const [assetsField] = useField<string[]>("assets")
  const [divisionField] = useField<string[]>("selectedDivisionId")
  const [assetAssignmentField, assetAssignmentMeta, assignmentHelpers] = useField<string>("assignment.selectedAssetId")

  const [{ data: divisionData }] = useQuery({
    query: DivisionsForAssetTransferDocument,
  })

  const divisionSelected = !!divisionField?.value
  const divisionId = `${divisionField?.value}` ?? ""

  const [{ data: selectedAssetData }] = useQuery({
    query: GetSelectedAssetDocument,
    variables: {
      assetId: assetIdToTransfer,
      projectId,
      divisionId,
    },
    pause: !assetIdToTransfer,
    requestPolicy: "cache-first",
  })

  const [{ data }] = useQuery({
    query: GetAssetDivisionTransferDocument,
    variables: { divisionId: divisionId ? divisionId : currentUser.organizationId },
    pause: !divisionSelected,
  })

  const divisionsAvailable = useMemo(() => {
    if (!divisionData?.myDivisions) return []

    const divisions = divisionData.myDivisions

    const sortedDivisions = divisions.sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))

    return sortedDivisions.map((division) => {
      return division
    })
  }, [divisionData])

  const availableAssets = useMemo(() => {
    const _assets = data?.assets ?? []
    const selectedAssets = assetsField.value

    return selectedAssets && selectedAssets.length > 0
      ? _assets.filter((asset) => !selectedAssets.includes(asset.id))
      : _assets
  }, [data?.assets, assetsField.value])
  const [assignmentType] = useField<string>("assignment.assignmentType")
  const userDivisionId = currentUser.divisionId ?? currentUser.organizationId

  const hasMissingReportTemplates = useMemo(() => {
    if (!divisionSelected || !data) return false

    const assetReportTemplates = selectedAssetData?.asset?.reportTemplates ?? []
    const divisionReportTemplates = data?.reusableAssetReportTemplates ?? []

    if (assetReportTemplates.length === 0) return false

    if (assetReportTemplates.length !== divisionReportTemplates.length) return true

    return assetReportTemplates.some(
      (template) => !divisionReportTemplates.some((divisionTemplate) => divisionTemplate.id === template.id)
    )
  }, [divisionSelected, selectedAssetData?.asset?.reportTemplates, data])

  return (
    <>
      <Typography className="pt-0 pb-6">
        To transfer the asset to a new division, reassignment to a new task, user, or other asset is required. The
        asset’s nested assets can be offloaded or retained.
      </Typography>

      <DivisionSelect
        required
        divisions={divisionsAvailable.filter((division) => division.id !== userDivisionId)}
        preselected={[{ value: divisionId, label: divisionId }]}
        name="selectedDivisionId"
        label="Division"
        className="w-full mb-0"
      />

      <TaskTypeRadioControls
        disabled={!divisionId}
        defaultAssignmentType="Task"
        availableAssetCount={availableAssets.length}
      />

      {assignmentType.value === "Task" && (
        <div className="col-span-12">
          <ProjectAndTasksMultiSelects
            disabled={!divisionId}
            formGroupId="assignment"
            divisionId={divisionId}
            includeCurrentAssignment
            withErrorHandling
          />
        </div>
      )}

      {assignmentType.value === "User" && (
        <UserSelect
          disabled={!divisionId}
          required
          divisionId={divisionId}
          name="assignment.selectedUserIds"
          label="Team Member"
          preselected={selectedUser ? [selectedUser] : []}
        />
      )}

      {assignmentType.value === "Asset" && (
        <FormControl fullWidth>
          <TextField
            disabled={!divisionId}
            select
            label="Assets *"
            name="assignment.selectedAssetId"
            helperText={
              assetAssignmentMeta.touched && assetAssignmentMeta.error ? assetAssignmentMeta.error : <>&nbsp;</>
            }
            error={assetAssignmentMeta.touched && !!assetAssignmentMeta.error}
            fullWidth
            size="small"
            value={assetAssignmentField.value || ""}
            onChange={(e) => {
              assignmentHelpers.setValue(e.target.value)
            }}
          >
            {availableAssets?.length ? (
              availableAssets.map((asset) => (
                <MenuItem key={asset.id} value={asset?.id}>
                  {asset?.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="" key="0">
                <em>No assets available to select</em>
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      )}

      {(selectedAssetData?.asset?.assetChildCount ?? 0) > 0 ? (
        <div className="flex items-center gap-3 my-4 ml-2">
          <FormikRadioGroup
            name="assetRetainment"
            options={[
              {
                label: "Offload nested assets",
                value: AssetRetainmentOption.Offload,
              },
              {
                label: "Retain nested assets",
                value: AssetRetainmentOption.Retain,
                disabled: hasQuantizedAssets,
              },
            ]}
          />
        </div>
      ) : (
        <div className="py-4" />
      )}
      {hasMissingReportTemplates && (
        <Alert severity="info">
          The division you are moving this asset to does not have an assigned inspection template active. If you proceed
          and move the asset, the missing inspection templates will be removed from this asset. Are you sure you wish to
          proceed?
        </Alert>
      )}
    </>
  )
}

const TaskTypeRadioControls: FC<{
  defaultAssignmentType: string
  disabled: boolean
  availableAssetCount: number
}> = ({ defaultAssignmentType, disabled, availableAssetCount }) => {
  const [assignmentType, , typeHelper] = useField<string>("assignment.assignmentType")

  const fields = [
    {
      label: "User",
      value: "User",
      weight: defaultAssignmentType === "User" ? 1 : 2,
    },
    {
      label: "Task",
      value: "Task",
      weight: defaultAssignmentType === "Task" ? 1 : 2,
    },
    {
      label: "Asset",
      value: "Asset",
      weight: defaultAssignmentType === "Asset" ? 1 : 3,
      showOption: availableAssetCount > 0,
    },
  ].sort((a, b) => {
    if (a.weight === b.weight) {
      return a.label.localeCompare(b.label)
    }
    return a.weight - b.weight
  })

  return (
    <RadioGroup
      name="assignment.assignmentType"
      row
      className="pt-0 pb-5 pl-2"
      value={assignmentType.value}
      onChange={(e) => typeHelper.setValue(e?.target?.value)}
    >
      {fields.map((field) => (
        <FormControlLabel
          disabled={disabled}
          key={field.value}
          value={field.value}
          control={<Radio />}
          label={field.label}
        />
      ))}
    </RadioGroup>
  )
}
