import {
  Avatar,
  Checkbox,
  Chip,
  MenuItem,
  Autocomplete as MuiAutoComplete,
  TextField as MuiTextField,
} from "@mui/material"
import { useField } from "formik"
import debounce from "lodash.debounce"
import { useCallback, useEffect, useState } from "react"
import { BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { UserSelectQuery } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { ListVisibilityFilter, Project, Task, User } from "../../graphql/generated/gql/graphql"
import { uniqueBy } from "../../helpers/uniqueBy"
import { SelectableUser } from "../../types/User"
import { PickPlus } from "../../types/helpers"
import UserWithTaskRow from "./MultiSelect/Rows/UserWithTaskRow"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"

type MyUser = SelectableUser &
  PickPlus<User, "imageUrl"> & {
    task?: PickPlus<Task, "id" | "name">
    project?: PickPlus<Project, "id" | "name">
  }
type Option = {
  value: string
  label: string
  user: MyUser
}

type UserFilter = (a: MyUser) => boolean

type Props = {
  name: string
  label: string
  divisionId?: string
  className?: string
  disabled?: boolean
  required?: boolean
  multiple?: boolean
  preselected?: MyUser[]
  userExclusionFilter?: UserFilter
  onChangeNotify?: (val: string[]) => void
}

const sortOptionsByProjectName = (a: Option, b: Option) => {
  // Ensure that "Overhead" is always at the top of the list
  const aKey = (a.user.project?.name || "").toLowerCase()
  const bKey = (b.user.project?.name || "").toLowerCase()

  if (aKey === "overhead") return -1
  if (bKey === "overhead") return 1

  // Sort by project name, ascending
  if (aKey > bKey) return 1
  if (aKey < bKey) return -1
  return 0
}

const UserSelectDocument = graphql(`
  query UserSelect($filter: UserFilter!, $first: Int, $after: String, $visibilityFilter: ListVisibilityFilter!) {
    users(
      filter: $filter
      first: $first
      after: $after
      visibilityFilter: $visibilityFilter
      sort: { by: projectName, direction: asc }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          lastName
          jobTitle
          imageUrl
          projectId
          task {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`)

type UserNode = NonNullable<UserSelectQuery["users"]["edges"][0]>["node"]

export const UserSelect = ({
  name,
  label,
  divisionId,
  className,
  disabled,
  required,
  multiple = false,
  onChangeNotify,
  preselected,
  userExclusionFilter,
}: Props) => {
  const [searchText, setSearchText] = useState("")
  const [cursor, setCursor] = useState<string | null>(null)
  const [loadedUsers, setLoadedUsers] = useState<UserNode[]>([])

  const currentUser = useCurrentUser()

  const [{ data }] = useQuery({
    query: UserSelectDocument,
    variables: {
      filter: {
        searchText,
        archived: false,
        divisionId: divisionId ?? null,
      },
      first: 15,
      after: cursor,
      visibilityFilter: currentUser.defaultListVisibilityFilter as ListVisibilityFilter,
    },
  })

  const [selected, setSelected] = useState<Option[]>(
    (preselected || []).map((u) => ({ value: u.id, label: `${u.firstName} ${u.lastName}`, user: u }))
  )
  const [_, { touched, error }, { setValue }] = useField(name)
  const displayError = touched && error && !disabled

  useEffect(() => {
    let queryData = (data?.users.edges || []).map((edge) => edge?.node || ({} as UserNode))
    if (userExclusionFilter) {
      queryData = queryData.filter(userExclusionFilter)
    }
    setLoadedUsers((prev) => [...prev, ...queryData])
  }, [data, userExclusionFilter])

  useEffect(() => {
    if (multiple) setValue(selected.map((item) => item.value))
    else setValue(selected?.[0]?.value)
  }, [selected, setValue, multiple])

  const preselectedOptions = (preselected || []).map((u) => ({
    value: u.id,
    label: `${u.firstName} ${u.lastName}`,
    user: u,
  }))
  const fetchedOptions = (loadedUsers || []).map((user) => ({
    value: user.id!,
    label: `${user.firstName} ${user.lastName}`,
    user,
  }))
  const options = uniqueBy([...preselectedOptions, ...fetchedOptions], "value").sort(sortOptionsByProjectName)

  const setSearchTextProperly = (newSearchTxt: string) => {
    setCursor(null)
    setLoadedUsers([])
    setSearchText(newSearchTxt)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(debounce(setSearchTextProperly, 500), [])

  // Implementation Docs for "search as you type"
  // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
  return (
    <MuiAutoComplete
      ListboxProps={{
        // This implementation would be removed after support for this feature lands:
        // https://github.com/mui/material-ui/pull/35653
        onScroll: (e) => {
          const listboxNode = e.currentTarget
          // Give a little fudge for maths
          if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 10) {
            setCursor(data?.users.pageInfo.endCursor || null)
          }
        },
      }}
      multiple
      disableCloseOnSelect={multiple}
      className={className}
      value={selected}
      options={options}
      renderOption={(props, option, { selected: selectedVal }) => {
        return (
          <MenuItem {...props} key={option.user.id}>
            {multiple && <Checkbox checked={selectedVal} />}
            <UserWithTaskRow key={option.user.id} user={option.user} />
          </MenuItem>
        )
      }}
      filterOptions={(x) => x.sort(sortOptionsByProjectName)}
      onChange={(_props, values) => {
        if (!Array.isArray(values)) setSelected([])
        else if (multiple) {
          setSelected(values)
        } else {
          const singleSelect = []
          const selectedOption = values[values.length - 1]
          if (selectedOption) singleSelect.push(selectedOption)
          setSelected(singleSelect)
        }
        const stringValues = values.map((value) => value.value)
        onChangeNotify?.(stringValues)
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          size="small"
          label={required ? `${label} *` : label}
          onChange={(e) => handleDebounce(e.target.value)}
          helperText={displayError ? error : <>&nbsp;</>}
          error={!!displayError}
          inputProps={{
            ...(params.inputProps || {}),
            style: { boxShadow: "none" },
          }}
          FormHelperTextProps={{ className: "my-0.5" }}
        />
      )}
      renderTags={(tagValue, getTagProps) => (
        <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
          {tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.value}
              label={option.label}
              variant="outlined"
              avatar={<Avatar src={option.user.imageUrl!} />}
              // Min width prevents long text from squishing the delete icon
              deleteIcon={<BiX className="size-4 min-w-[16px]" />}
              // Max with truncates the text. This is the recommended way to handle long text in chips
              style={{ maxWidth: 230 }}
            />
          ))}
        </div>
      )}
      isOptionEqualToValue={(option, value) => value?.value === option?.value}
      groupBy={(option) => option.user.project?.name || ""}
    />
  )
}
